<template>
  <div>
    <!-- <Heading content="Application">
    </Heading>
    <hr /> -->
    <div v-if="application">
      <Heading class="mb-4" content="Personal" heading="h4"></Heading>
      <el-form label-width="250px">
        <el-form-item :label="$t('registerDetail.email')">
          {{ application.email }}
        </el-form-item>
        <el-form-item :label="$t('teacherDetail.phone')">
          {{ application.phone }}
        </el-form-item>
      </el-form>
      <hr />
      <Heading class="mb-4" content="Education" heading="h4"></Heading>
      <el-form label-width="250px">
        <el-form-item :label="$t('teacherDetail.college')">
          {{ application.school }}
        </el-form-item>
        <el-form-item :label="$t('teacherDetail.gradeLevel')">
          {{ application.graduation_year }}
        </el-form-item>
        <el-form-item :label="$t('teacherDetail.major')">
          {{ application.major }}
        </el-form-item>
        <el-form-item label="Cumulative GPA">
          {{ application.gpa_score }}
        </el-form-item>
      </el-form>
      <hr />
      <Heading class="mb-4" content="Background" heading="h4"></Heading>
      <el-form label-width="250px">
        <el-form-item label="What other school(s) also accepted you?">
          {{ application.other_schools_accepted }}
        </el-form-item>
        <el-form-item
          label="What language(s) other than English do you know? Please very briefly
    describe your comfort level."
        >
          {{ application.other_languages }}
        </el-form-item>
        <el-form-item label="What position(s) are you applying for?">
          <el-checkbox-group :value="application.apply_for_positions" disabled>
            <div
              v-for="(checkItem, index) in checkList"
              :key="index"
              style="height:30px;line-height:30px;"
            >
              <el-checkbox :label="index">
                {{ checkItem.name }}
              </el-checkbox>
            </div>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="How did you hear about us?">
          {{ application.know_us }}
        </el-form-item>
      </el-form>
      <hr />
      <Heading class="mb-4" content="Short responses" heading="h4"></Heading>
      <!-- ans_to_extracurriculars -->
      <div class="mt-4">
        <b>{{ $t("userProfile.teacher.ans_to_extracurriculars") }}</b>
      </div>
      <div class="mb-4">
        {{ application.ans_to_extracurriculars }}
      </div>
      <!-- ans_to_teaching_years -->
      <div>
        <b>{{ $t("userProfile.teacher.ans_to_teaching_years") }}</b>
      </div>
      <div class="mb-4">
        {{ application.ans_to_teaching_years }}
      </div>
      <!-- ans_to_student_numbers -->
      <div>
        <b>{{ $t("userProfile.teacher.ans_to_student_numbers") }}</b>
      </div>
      <div class="mb-4">
        {{ application.ans_to_student_numbers }}
      </div>
      <!-- ans_to_capable_subjects -->
      <div>
        <b>{{ $t("userProfile.teacher.ans_to_capable_subjects") }}</b>
      </div>
      <div class="mb-4">
        {{ application.ans_to_capable_subjects }}
      </div>
      <!-- ans_to_relevant_work -->
      <div>
        <b>{{ $t("userProfile.teacher.ans_to_relevant_work") }}</b>
      </div>
      <div class="mb-4" label-width="0px">
        {{ application.ans_to_relevant_work }}
      </div>
      <!-- ans_to_students_perform -->
      <div>
        <b>{{ $t("userProfile.teacher.ans_to_students_perform") }}</b>
      </div>
      <div class="mb-4">
        {{ application.ans_to_students_perform }}
      </div>

      <div class="mt-4">
        <b>High school resume</b>
      </div>
      <div v-if="application.high_school_resume_url">
        <a :href="application.high_school_resume_url" target="_blank">
          查看檔案內容 <i class="fas fa-eye action-icon" />
        </a>
      </div>

      <div class="mt-4">
        <b>College resume</b>
      </div>
      <div v-if="application.college_resume_url">
        <a :href="application.college_resume_url" target="_blank">
          查看檔案內容 <i class="fas fa-eye action-icon" />
        </a>
      </div>

      <div class="mt-4">
        <b>College application essay</b>
      </div>
      <div v-if="application.essay_url">
        <a :href="application.essay_url" target="_blank">
          查看檔案內容 <i class="fas fa-eye action-icon" />
        </a>
      </div>
      <hr />
    </div>
    <el-empty v-else description="暫無申請表"></el-empty>
  </div>
</template>

<script>
import userApi from "@/apis/user";
import { instant } from "@ivy-way/material";

export default {
  components: {},
  mixins: [],
  props: ["email"],
  data() {
    return {
      application: null,
      checkList: [
        {
          name: "Part-time Instructor (Online)"
        },
        {
          name: "Part-time Instructor (Taiwan)"
        },
        {
          name: "Summer Instructor (Taiwan)"
        },
        {
          name: "Full-time Instructor (Taiwan)"
        },
        {
          name: "Full-time Branch Manager (Taiwan)"
        }
      ]
    };
  },
  computed: {
    instant() {
      return instant;
    }
  },
  watch: {
    email() {
      this.getApplicationInfo();
    }
  },
  created() {
    this.getApplicationInfo();
  },
  methods: {
    async getApplicationInfo() {
      if (this.email) {
        const res = await userApi.getTeacherApplicationByEmail({
          // email: "moo79928@zslsz.com"
          email: this.email
        });
        this.application = res.teacher_application;
      }
    }
  }
};
</script>

<style scoped>
::v-deep .el-form-item__label {
  font-weight: 700;
  text-align: left;
  min-height: 20px;
  display: flex;
  font-size: 1rem;
  align-items: center;
  line-height: 1.2rem;
}
::v-deep .el-form-item__content {
  line-height: 20px;
}
::v-deep .el-form-item {
  margin-bottom: 10px;
}
b {
  color: #606266;
}
</style>
