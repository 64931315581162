<template>
  <!-- Form -->
  <div>
    <el-dialog
      :visible.sync="dialogFormVisible"
      :before-close="updateVisible"
      :close-on-click-modal="false"
      width="70%"
    >
      <div slot="title">
        <h3>{{ $t("userProfile.dialog.edit") }}</h3>
      </div>

      <el-form>
        <el-form-item :label="$t('userProfile.student.school')">
          <el-input
            v-model="newProfile.high_school"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('userProfile.student.gradeLevel')">
          <GradeLevelSelector
            :value="newProfile.high_school_graduation_year"
            @change="
              gradeLevel => {
                newProfile.high_school_graduation_year = gradeLevel;
              }
            "
          />
        </el-form-item>
        <el-form-item :label="$t('userProfile.student.classTaken')">
          <el-input
            v-model="newProfile.taken_class_where"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('userProfile.student.comments')">
          <el-input
            v-model="newProfile.taken_class_comments"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('userProfile.student.prefLang')">
          <el-input
            v-model="newProfile.lang_pref"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('userProfile.student.knowUsFrom')">
          <el-input v-model="newProfile.know_us" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item :label="$t('userProfile.student.referral')">
          <el-input
            v-model="newProfile.recommendation_info"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div>
            <el-tooltip
              content="如果找不到，請手動建立家長"
              placement="top"
              popper-class="tooltipColor"
            >
              <span class="action-icon">
                <i class="fas fa-question-circle" />
              </span>
            </el-tooltip>
            <span style="margin-right:5px">
              {{ $t("userProfile.student.parent") }}
            </span>
            <router-link :to="{ name: 'QuickRegister' }">
              <el-button type="primary" icon="el-icon-plus" size="mini">
                建立家長
              </el-button>
            </router-link>
          </div>
          <el-select
            value=""
            filterable
            v-model="studentParent"
            @change="addParents"
            style="width:100%"
          >
            <el-option
              v-for="parent in attachSelfStudent"
              :key="parent.id"
              :value="parent"
              :label="`${user.displayName(parent.first_name, parent.last_name)} (${parent.email})`"
            >
              <!-- {{ ) }} ({{
                
              }}) -->
            </el-option>
          </el-select>

          <ol>
            <li
              :key="parent.pivot.parent_user_id"
              v-for="parent in newProfile.parents"
            >
              {{ `${parent.name}` }}
              <span v-if="isParentOfSelf(parent.pivot.parent_user_id)"
                >（這是學生本人哦！）</span
              >
              <span
                class="text-danger"
                @click="
                  () => {
                    removeParent(parent.pivot.parent_user_id);
                  }
                "
              >
                <i class="el-icon-delete" />
              </span>
            </li>
          </ol>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="updateVisible">
          {{ $t("userProfile.dialog.cancel") }}
        </el-button>
        <el-button type="primary" @click="submitChange">
          {{ $t("userProfile.dialog.save") }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { user } from "@ivy-way/material";
import _ from "lodash";
import { GradeLevelSelector } from "@/components/selector";

export default {
  props: ["type", "editDialog", "profile", "id", "studentName"],
  data() {
    return {
      formLabelWidth: "120px",
      dialogFormVisible: this.editDialog,
      newProfile: {},
      studentParent: null
    };
  },
  components: {
    GradeLevelSelector
  },
  mounted() {
    this.newProfile = {
      ..._.cloneDeep(this.profile),
      parents: this.profile.parents.map(parent => ({
        ...parent,
        name: `${parent.first_name} ${parent.last_name}`
      }))
    };
  },
  computed: {
    user() {
      return user;
    },
    parents() {
      return this.$store.getters["profile/getParents"];
    },
    attachSelfStudent() {
      return [
        { name: "無（學生自行付款）", pivot: { parent_user_id: this.id } },
        ...this.parents
      ];
    }
  },
  async created() {
    this.$store.dispatch("profile/fetchParents");
  },
  methods: {
    isParentOfSelf(parentId) {
      return parentId === Number(this.id);
    },
    
    addParents(parent) {
      const isParentExist = id =>
        this.newProfile.parents.some(
          parent => parent.pivot.parent_user_id === id
        );

      if (isParentExist(parent.pivot.parent_user_id)){
        this.studentParent = null;
        return;
      };
      if (this.isParentOfSelf(parent.pivot.parent_user_id)) {
        this.newProfile.parents = [
          ...this.newProfile.parents,
          { ...parent, name: this.studentName }
        ];
      } else {
        this.newProfile.parents = [...this.newProfile.parents, parent];
      }
      this.studentParent = null;
    },
    removeParent(targetParentId) {
      this.newProfile.parents = this.newProfile.parents.filter(
        parent => parent.pivot.parent_user_id !== targetParentId
      );
    },
    updateVisible() {
      this.dialogFormVisible = false;
      this.$emit("updateDialog", "cancel");
    },
    async submitChange() {
      this.dialogFormVisible = false;
      const newObj = {
        id: this.id,
        newProfile: {
          ...this.newProfile,
          parent_ids: this.newProfile.parents.map(
            parent => parent.pivot.parent_user_id
          )
        }
      };

      await this.$store.dispatch("profile/updateStudentProfileByAdmin", newObj);
      this.$emit("updateDialog", "submit");
    }
  }
};
</script>
