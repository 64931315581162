<template>
  <!-- Form -->
  <div>
    <el-dialog
      :visible.sync="dialogFormVisible"
      :before-close="updateVisible"
      :close-on-click-modal="false"
      width="70%"
    >
      <div slot="title">
        <h3>{{ $t("userProfile.dialog.edit") }}</h3>
      </div>

      <!-- SAT -->
      <div>
        <el-checkbox
          :indeterminate="satIsIndeterminate"
          v-model="satCheckAll"
          @change="satHandleCheckAllChange"
        >
          <h3>SAT</h3>
        </el-checkbox>
        <div style="margin: 15px 0;"></div>
        <el-checkbox-group
          v-model="satSelectedArray"
          @change="satHandleCheckedChange"
        >
          <el-checkbox
            v-for="sat in satOptions"
            :label="sat.value"
            :key="sat.label"
            >{{ $t("subjects.name", { msg: sat.label }) }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
      <el-divider></el-divider>

      <!-- ACT -->
      <div>
        <el-checkbox
          :indeterminate="actIsIndeterminate"
          v-model="actCheckAll"
          @change="actHandleCheckAllChange"
        >
          <h3>ACT</h3>
        </el-checkbox>
        <div style="margin: 15px 0;"></div>
        <el-checkbox-group
          v-model="actSelectedArray"
          @change="actHandleCheckedChange"
        >
          <el-checkbox
            v-for="act in actOptions"
            :label="act.value"
            :key="act.label"
            >{{ $t("subjects.name", { msg: act.label }) }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
      <el-divider></el-divider>

      <!-- TOEFL -->
      <div>
        <el-checkbox
          :indeterminate="toeflIsIndeterminate"
          v-model="toeflCheckAll"
          @change="toeflHandleCheckAllChange"
        >
          <h3>TOEFL</h3>
        </el-checkbox>
        <div style="margin: 15px 0;"></div>
        <el-checkbox-group
          v-model="toeflSelectedArray"
          @change="toeflHandleCheckedChange"
        >
          <el-checkbox
            v-for="toefl in toeflOptions"
            :label="toefl.value"
            :key="toefl.label"
            >{{ $t("subjects.name", { msg: toefl.label }) }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
      <el-divider></el-divider>

      <!-- Counseling -->
      <div>
        <el-checkbox
          :indeterminate="counselingIsIndeterminate"
          v-model="counselingCheckAll"
          @change="counselingHandleCheckAllChange"
        >
          <h3>Counseling</h3>
        </el-checkbox>
        <div style="margin: 15px 0;"></div>
        <el-checkbox-group
          v-model="counselingSelectedArray"
          @change="counselingHandleCheckedChange"
        >
          <el-checkbox
            v-for="counseling in counselingOptions"
            :label="counseling.value"
            :key="counseling.label"
            >{{ $t("subjects.name", { msg: counseling.label }) }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
      <el-divider></el-divider>

      <!-- AP -->
      <div>
        <el-checkbox
          :indeterminate="apIsIndeterminate"
          v-model="apCheckAll"
          @change="apHandleCheckAllChange"
        >
          <h3>AP</h3>
        </el-checkbox>
        <div style="margin: 15px 0;"></div>
        <el-checkbox-group
          v-model="apSelectedArray"
          @change="apHandleCheckedChange"
        >
          <el-checkbox
            v-for="ap in apOptions"
            :label="ap.value"
            :key="ap.label"
            class="d-block pl-3"
            >{{ $t("subjects.name", { msg: ap.label }) }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
      <el-divider></el-divider>

      <!-- IB -->
      <div>
        <el-checkbox
          :indeterminate="ibIsIndeterminate"
          v-model="ibCheckAll"
          @change="ibHandleCheckAllChange"
        >
          <h3>IB</h3>
        </el-checkbox>
        <div style="margin: 15px 0;"></div>
        <el-checkbox-group
          v-model="ibSelectedArray"
          @change="ibHandleCheckedChange"
        >
          <el-checkbox
            v-for="ib in ibOptions"
            :label="ib.value"
            :key="ib.label"
            class="d-block pl-3"
            >{{ $t("subjects.name", { msg: ib.label }) }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
      <el-divider></el-divider>

      <!-- School -->
      <div>
        <el-checkbox
          :indeterminate="schoolIsIndeterminate"
          v-model="schoolCheckAll"
          @change="schoolHandleCheckAllChange"
        >
          <h3>School</h3>
        </el-checkbox>
        <div style="margin: 15px 0;"></div>
        <el-checkbox-group
          v-model="schoolSelectedArray"
          @change="schoolHandleCheckedChange"
        >
          <el-checkbox
            v-for="school in schoolOptions"
            :label="school.value"
            :key="school.label"
            class="d-block pl-3"
            >{{ $t("subjects.name", { msg: school.label }) }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
      <el-divider></el-divider>

      <!-- GRE -->
      <div>
        <el-checkbox
          :indeterminate="greIsIndeterminate"
          v-model="greCheckAll"
          @change="greHandleCheckAllChange"
        >
          <h3>GRE</h3>
        </el-checkbox>
        <div style="margin: 15px 0;"></div>
        <el-checkbox-group
          v-model="greSelectedArray"
          @change="greHandleCheckedChange"
        >
          <el-checkbox
            v-for="gre in greOptions"
            :label="gre.value"
            :key="gre.label"
            class="d-block pl-3"
            >{{ $t("subjects.name", { msg: gre.label }) }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
      <el-divider></el-divider>

      <!-- GMAT -->
      <div>
        <el-checkbox
          :indeterminate="gmatIsIndeterminate"
          v-model="gmatCheckAll"
          @change="gmatHandleCheckAllChange"
        >
          <h3>GMAT</h3>
        </el-checkbox>
        <div style="margin: 15px 0;"></div>
        <el-checkbox-group
          v-model="gmatSelectedArray"
          @change="gmatHandleCheckedChange"
        >
          <el-checkbox
            v-for="gmat in gmatOptions"
            :label="gmat.value"
            :key="gmat.label"
            class="d-block pl-3"
            >{{ $t("subjects.name", { msg: gmat.label }) }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
      <el-divider></el-divider>

      <!-- MCAT -->
      <div>
        <el-checkbox
          :indeterminate="mcatIsIndeterminate"
          v-model="mcatCheckAll"
          @change="mcatHandleCheckAllChange"
        >
          <h3>MCAT</h3>
        </el-checkbox>
        <div style="margin: 15px 0;"></div>
        <el-checkbox-group
          v-model="mcatSelectedArray"
          @change="mcatHandleCheckedChange"
        >
          <el-checkbox
            v-for="mcat in mcatOptions"
            :label="mcat.value"
            :key="mcat.label"
            class="d-block pl-3"
            >{{ $t("subjects.name", { msg: mcat.label }) }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
      <el-divider></el-divider>

      <div slot="footer" class="dialog-footer">
        <el-button @click="updateVisible">{{
          $t("userProfile.dialog.cancel")
        }}</el-button>
        <el-button type="primary" @click="submitChange">{{
          $t("userProfile.dialog.save")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import subjectsData from "@/data/subjects.json";
import _ from "lodash";
const satOptions = subjectsData.find(x => x.label === "SAT");
const actOptions = subjectsData.find(x => x.label === "ACT");
const toeflOptions = subjectsData.find(x => x.label === "TOEFL");
const counselingOptions = subjectsData.find(x => x.label === "Counseling");
const apOptions = subjectsData.find(x => x.label === "AP");
const ibOptions = subjectsData.find(x => x.label === "IB");
const schoolOptions = subjectsData.find(x => x.label === "School");
const greOptions = subjectsData.find(x => x.label === "GRE");
const gmatOptions = subjectsData.find(x => x.label === "GMAT");
const mcatOptions = subjectsData.find(x => x.label === "MCAT");
export default {
  props: ["editDialogSubjects", "profile", "id"],
  data() {
    return {
      formLabelWidth: "120px",
      dialogFormVisible: this.editDialogSubjects,
      newProfile: {},
      // SAT
      satSelectedArray: [],
      satCheckAll: false,
      satOptions: satOptions.options,
      satIsIndeterminate: false,
      // ACT
      actSelectedArray: [],
      actCheckAll: false,
      actOptions: actOptions.options,
      actIsIndeterminate: false,

      // TOEFL
      toeflSelectedArray: [],
      toeflCheckAll: false,
      toeflOptions: toeflOptions.options,
      toeflIsIndeterminate: false,

      // Counseling
      counselingSelectedArray: [],
      counselingCheckAll: false,
      counselingOptions: counselingOptions.options,
      counselingIsIndeterminate: false,

      // AP
      apSelectedArray: [],
      apCheckAll: false,
      apOptions: apOptions.options,
      apIsIndeterminate: false,

      // IB
      ibSelectedArray: [],
      ibCheckAll: false,
      ibOptions: ibOptions.options,
      ibIsIndeterminate: false,

      // School
      schoolSelectedArray: [],
      schoolCheckAll: false,
      schoolOptions: schoolOptions.options,
      schoolIsIndeterminate: false,

      // GRE
      greSelectedArray: [],
      greCheckAll: false,
      greOptions: greOptions.options,
      greIsIndeterminate: false,

      // GMAT
      gmatSelectedArray: [],
      gmatCheckAll: false,
      gmatOptions: gmatOptions.options,
      gmatIsIndeterminate: false,

      // MCAT
      mcatSelectedArray: [],
      mcatCheckAll: false,
      mcatOptions: mcatOptions.options,
      mcatIsIndeterminate: false
    };
  },
  mounted() {
    this.newProfile = _.cloneDeep(this.profile);
    const currSelected = this.newProfile.subjects;
    currSelected.forEach(x => {
      // Regex
      if (/^sat[^2]/.test(x)) {
        this.satSelectedArray.push(x);
      } else if (/^act/.test(x)) {
        this.actSelectedArray.push(x);
      } else if (/^counseling/.test(x)) {
        this.counselingSelectedArray.push(x);
      } else if (/^toefl/.test(x)) {
        this.toeflSelectedArray.push(x);
      } else if (/^ap/.test(x)) {
        this.apSelectedArray.push(x);
      } else if (/^ib/.test(x)) {
        this.ibSelectedArray.push(x);
      } else if (/^school/.test(x)) {
        this.schoolSelectedArray.push(x);
      } else if (/^gre/.test(x)) {
        this.greSelectedArray.push(x);
      } else if (/^gmat/.test(x)) {
        this.gmatSelectedArray.push(x);
      } else if (/^mcat/.test(x)) {
        this.mcatSelectedArray.push(x);
      }
    });
    this.satHandleCheckedChange(this.satSelectedArray);
    this.actHandleCheckedChange(this.actSelectedArray);
    this.toeflHandleCheckedChange(this.toeflSelectedArray);
    this.counselingHandleCheckedChange(this.counselingSelectedArray);
    this.apHandleCheckedChange(this.apSelectedArray);
    this.ibHandleCheckedChange(this.ibSelectedArray);
    this.schoolHandleCheckedChange(this.schoolSelectedArray);
    this.greHandleCheckedChange(this.greSelectedArray);
    this.gmatHandleCheckedChange(this.gmatSelectedArray);
    this.mcatHandleCheckedChange(this.mcatSelectedArray);
  },
  methods: {
    // SAT
    satHandleCheckAllChange(val) {
      if (val) {
        satOptions.options.forEach(x => {
          if (!this.satSelectedArray.includes(x.value)) {
            this.satSelectedArray.push(x.value);
          }
        });
      } else {
        this.satSelectedArray = [];
      }
      this.satIsIndeterminate = false;
    },
    satHandleCheckedChange(value) {
      let checkedCount = value.length;
      this.satCheckAll = checkedCount === this.satOptions.length;
      this.satIsIndeterminate =
        checkedCount > 0 && checkedCount < this.satOptions.length;
    },
    // ACT
    actHandleCheckAllChange(val) {
      if (val) {
        actOptions.options.forEach(x => {
          if (!this.actSelectedArray.includes(x.value)) {
            this.actSelectedArray.push(x.value);
          }
        });
      } else {
        this.actSelectedArray = [];
      }
      this.actIsIndeterminate = false;
    },
    actHandleCheckedChange(value) {
      let checkedCount = value.length;
      this.actCheckAll = checkedCount === this.actOptions.length;
      this.actIsIndeterminate =
        checkedCount > 0 && checkedCount < this.actOptions.length;
    },
    // TOEFL
    toeflHandleCheckAllChange(val) {
      if (val) {
        toeflOptions.options.forEach(x => {
          if (!this.toeflSelectedArray.includes(x.value)) {
            this.toeflSelectedArray.push(x.value);
          }
        });
      } else {
        this.toeflSelectedArray = [];
      }
      this.toeflIsIndeterminate = false;
    },
    toeflHandleCheckedChange(value) {
      let checkedCount = value.length;
      this.toeflCheckAll = checkedCount === this.toeflOptions.length;
      this.toeflIsIndeterminate =
        checkedCount > 0 && checkedCount < this.toeflOptions.length;
    },
    // Counseling
    counselingHandleCheckAllChange(val) {
      if (val) {
        counselingOptions.options.forEach(x => {
          if (!this.counselingSelectedArray.includes(x.value)) {
            this.counselingSelectedArray.push(x.value);
          }
        });
      } else {
        this.counselingSelectedArray = [];
      }
      this.counselingIsIndeterminate = false;
    },
    counselingHandleCheckedChange(value) {
      let checkedCount = value.length;
      this.counselingCheckAll = checkedCount === this.counselingOptions.length;
      this.counselingIsIndeterminate =
        checkedCount > 0 && checkedCount < this.counselingOptions.length;
    },
    // AP
    apHandleCheckAllChange(val) {
      if (val) {
        apOptions.options.forEach(x => {
          if (!this.apSelectedArray.includes(x.value)) {
            this.apSelectedArray.push(x.value);
          }
        });
      } else {
        this.apSelectedArray = [];
      }
      this.apIsIndeterminate = false;
    },
    apHandleCheckedChange(value) {
      let checkedCount = value.length;
      this.apCheckAll = checkedCount === this.apOptions.length;
      this.apIsIndeterminate =
        checkedCount > 0 && checkedCount < this.apOptions.length;
    },
    // IB
    ibHandleCheckAllChange(val) {
      if (val) {
        ibOptions.options.forEach(x => {
          if (!this.ibSelectedArray.includes(x.value)) {
            this.ibSelectedArray.push(x.value);
          }
        });
      } else {
        this.ibSelectedArray = [];
      }
      this.ibIsIndeterminate = false;
    },
    ibHandleCheckedChange(value) {
      let checkedCount = value.length;
      this.ibCheckAll = checkedCount === this.ibOptions.length;
      this.ibIsIndeterminate =
        checkedCount > 0 && checkedCount < this.ibOptions.length;
    },
    // School
    schoolHandleCheckAllChange(val) {
      if (val) {
        schoolOptions.options.forEach(x => {
          if (!this.schoolSelectedArray.includes(x.value)) {
            this.schoolSelectedArray.push(x.value);
          }
        });
      } else {
        this.schoolSelectedArray = [];
      }
      this.schoolIsIndeterminate = false;
    },
    schoolHandleCheckedChange(value) {
      let checkedCount = value.length;
      this.schoolCheckAll = checkedCount === this.schoolOptions.length;
      this.schoolIsIndeterminate =
        checkedCount > 0 && checkedCount < this.schoolOptions.length;
    },
    // Gre
    greHandleCheckAllChange(val) {
      if (val) {
        greOptions.options.forEach(x => {
          if (!this.greSelectedArray.includes(x.value)) {
            this.greSelectedArray.push(x.value);
          }
        });
      } else {
        this.greSelectedArray = [];
      }
      this.greIsIndeterminate = false;
    },
    greHandleCheckedChange(value) {
      let checkedCount = value.length;
      this.greCheckAll = checkedCount === this.greOptions.length;
      this.greIsIndeterminate =
        checkedCount > 0 && checkedCount < this.greOptions.length;
    },
    // Gmat
    gmatHandleCheckAllChange(val) {
      if (val) {
        gmatOptions.options.forEach(x => {
          if (!this.gmatSelectedArray.includes(x.value)) {
            this.gmatSelectedArray.push(x.value);
          }
        });
      } else {
        this.gmatSelectedArray = [];
      }
      this.gmatIsIndeterminate = false;
    },
    gmatHandleCheckedChange(value) {
      let checkedCount = value.length;
      this.gmatCheckAll = checkedCount === this.gmatOptions.length;
      this.gmatIsIndeterminate =
        checkedCount > 0 && checkedCount < this.gmatOptions.length;
    },
    // Mcat
    mcatHandleCheckAllChange(val) {
      if (val) {
        mcatOptions.options.forEach(x => {
          if (!this.mcatSelectedArray.includes(x.value)) {
            this.mcatSelectedArray.push(x.value);
          }
        });
      } else {
        this.mcatSelectedArray = [];
      }
      this.mcatIsIndeterminate = false;
    },
    mcatHandleCheckedChange(value) {
      let checkedCount = value.length;
      this.mcatCheckAll = checkedCount === this.mcatOptions.length;
      this.mcatIsIndeterminate =
        checkedCount > 0 && checkedCount < this.mcatOptions.length;
    },

    updateVisible() {
      this.dialogFormVisible = false;
      this.$emit("updateDialog", "cancel");
    },
    async submitChange() {
      const res = [
        ...this.satSelectedArray,
        ...this.actSelectedArray,
        ...this.toeflSelectedArray,
        ...this.counselingSelectedArray,
        ...this.apSelectedArray,
        ...this.ibSelectedArray,
        ...this.schoolSelectedArray,
        ...this.greSelectedArray,
        ...this.gmatSelectedArray,
        ...this.mcatSelectedArray
      ];
      this.newProfile.subjects = res;
      // this.newProfile.subjects = [];
      const newObj = {
        id: this.id,
        newProfile: this.newProfile
      };
      await this.$store.dispatch("profile/updateTeacherProfileByAdmin", newObj);
      this.$emit("updateDialog", "submit");
    }
  }
};
</script>

<style></style>
