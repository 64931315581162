<template>
  <div>
    <h3>
      <i class="fab fa-line"></i>
      記事本
    </h3>
    <hr />
    <el-dialog
      title="更新所有用戶的Line記事本"
      :visible.sync="dialogVisible"
      width="50%"
    >
      <el-alert class="mb-4" type="warning" :closable="false" show-icon>
        更新時間大概3~5分鐘，請更新完成后刷新查看。
      </el-alert>
      <el-form label-width="80px">
        <el-form-item label="Cookie">
          <el-input type="textarea" :rows="10" v-model="cookie"></el-input>
          <small class="text-danger" v-show="this.cookie_valid != 1">
            cookie已經失效，請重新獲取。
          </small>
          <small>
            <a
              target="_blank"
              href="https://drive.google.com/file/d/1TkUB2nRg5JQQXJXbwe_MhagDETu7UeKs/view"
            >
              查看cookie獲取方式
            </a>
          </small>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="getLineInfo" :disabled="!cookie"
          >更 新</el-button
        >
      </span>
    </el-dialog>
    <el-tabs type="border-card" v-if="lineChats && lineChats.length > 0">
      <el-tab-pane
        :label="chat.nickname ? chat.nickname : chat.name"
        v-for="chat in lineChats"
        :key="chat.id"
      >
        <h6>
          <a
            :href="
              `https://chat.line.biz/U44514bd291de204d65a9663224fa447d/chat/${chat.id}`
            "
            target="_blank"
          >
            <i class="fab fa-line"></i>
            進入"{{ chat.nickname ? chat.nickname : chat.name }}"的聊天室
          </a>
        </h6>
        <el-table :data="chat.line_notes" style="width: 100%">
          <el-table-column prop="body" label="記事本">
            <template slot-scope="scope">
              {{ instant.formatGoogleTime(scope.row.note_created_at) }} -
              <b>{{ scope.row.body }}</b>
              <template
                v-if="
                  instant.formatGoogleTime(scope.row.note_updated_at) !==
                    instant.formatGoogleTime(scope.row.note_created_at)
                "
              >
                <span class="text-success"
                  >【最後更新時間：{{
                    instant.formatGoogleTime(scope.row.note_updated_at)
                  }}】</span
                >
              </template>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
    <el-empty v-else description="暫無該用戶記事本訊息"></el-empty>
    <el-alert class="mt-4 mb-4" show-icon :closable="false">
      <div
        class="d-flex"
        style="justify-content:space-between; align-items:center; width: 100%;"
      >
        <el-button
          v-if="this.cookie_valid == 1"
          type="primary"
          size="small"
          @click="getLineInfo"
        >
          <i class="fas fa-upload"></i>
          更新所有用戶的Line記事本
        </el-button>
        <el-button
          type="danger"
          v-if="this.cookie_valid != 1"
          size="small"
          @click="dialogVisible = true"
        >
          Cookie已經失效，請重新獲取並更新所有用戶的Line記事本
        </el-button>
        <!-- <small class="text-danger" v-show="this.cookie_valid != 1">
          cookie已經失效，請重新獲取。
        </small> -->
        <div>
          <small style="color: #909399;">
            上次更新時間:
            <b v-if="updateDate">{{ instant.formatGoogleTime(updateDate) }}</b>
            <b v-else> - </b>
          </small>
        </div>
      </div>
    </el-alert>
  </div>
</template>

<script>
import userApi from "@/apis/user";
import { instant } from "@ivy-way/material";

export default {
  components: {},
  mixins: [],
  props: ["id"],
  data() {
    return {
      lineChats: [],
      dialogVisible: false,
      updateDate: null,
      cookie: null,
      cookie_valid: null
    };
  },
  computed: {
    instant() {
      return instant;
    }
  },
  created() {
    this.getLineNotes();
    this.getLineNotesDate();
  },
  methods: {
    async getLineInfo() {
      this.dialogVisible = false;
      const res = await userApi.getLineInfo({
        cookie: this.cookie
      });
    },
    async getLineNotes() {
      const res = await userApi.getLineNotes(this.id);
      this.lineChats = res.user.line_chats;
    },
    async getLineNotesDate() {
      const res = await userApi.getLineNotesDate();
      this.updateDate = res.line_note_last_sync_at;
      this.cookie_valid = res.is_line_note_crawler_cookie_valid;
      if (this.cookie_valid == 1) {
        this.cookie = res.line_note_crawler_cookie;
      }
    }
  }
};
</script>

<style scoped>
::v-deep .el-alert__content {
  width: 100%;
}
</style>
