<template>
  <div>
    <br />
    <el-row>
      <div class="pb-3 d-flex">
        <span class="pt-1 pr-2">
          <h3>
            {{ $t("userProfile.student.studentInfo") }}
            <el-tooltip
              v-if="isRoleAdmin() || isRoleParent()"
              class="item"
              :content="$t('userProfile.dialog.edit')"
              placement="top"
              popper-class="tooltipColor"
            >
              <span @click="editDialog = true">
                <i class="el-icon-edit-outline action-icon" />
              </span>
            </el-tooltip>
          </h3>
        </span>
      </div>
    </el-row>
    <DialogEnrollSession
      :dialogVisible="addEnrollmentDialogVisible"
      @toggleEnrollmentDialogVisible="toggleEnrollmentDialogVisible"
    />
    <template v-if="editDialog">
      <EditDialogStudent
        :editDialog="editDialog"
        :profile="profile"
        :studentName="
          `${user.displayName(userDataByAdmin.basic_info.first_name,userDataByAdmin.basic_info.last_name)}`
        "
        :id="id"
        @updateDialog="updateDialog"
      ></EditDialogStudent>
    </template>

    <template v-if="editDialogTest">
      <EditDialogTest
        type="student"
        :editDialogTest="editDialogTest"
        :currentSelectTest="currentSelectTest"
        :profile="profile"
        :currTableData="testTableData"
        :id="id"
        @updateDialog="updateDialog"
      ></EditDialogTest>
    </template>

    <template v-if="editCounselintDialog">
      <EditDialogCounseling
        :editDialog="editCounselintDialog"
        :profile="profile"
        :id="id"
        @updateDialog="updateDialog"
      />
    </template>

    <!-- 學生資料 -->
    <el-table :data="studentTableData" :show-header="false">
      <el-table-column prop="name" width="auto">
        <template slot-scope="scope">{{ $t(scope.row.name) }}</template>
      </el-table-column>

      <el-table-column prop="data" width="auto">
        <template slot-scope="scope">
          <template v-if="scope.row.parent">
            <div v-for="(item, key) in scope.row.data" :key="key">
              <router-link
                v-if="isRoleAdmin()"
                :to="{
                  name: 'ProfileForAdmin',
                  params: { id: item.pivot.parent_user_id }
                }"
              >
                {{ user.displayName(item.first_name, item.last_name) }}
              </router-link>
              <span v-else>
                {{ user.displayName(item.first_name, item.last_name) }}
              </span>
            </div>
          </template>
          <template v-else>
            <slot>{{ scope.row.data }}</slot>
          </template>
        </template>
      </el-table-column>

      <el-table-column width="auto"></el-table-column>
    </el-table>
    <br />
    <!-- 考試資料  -->
    <el-row>
      <div class="pt-4">
        <h3>
          {{ $t("userProfile.student.testInfo") }}
          <el-tooltip
            v-if="isRoleAdmin() || isRoleParent()"
            class="item"
            :content="$t('userProfile.dialog.edit')"
            placement="top"
            popper-class="tooltipColor"
          >
            <span @click="openTestEdit">
              <i class="el-icon-edit-outline action-icon" />
            </span>
          </el-tooltip>
        </h3>
      </div>
    </el-row>
    <el-table :data="notNoneTestTableData" :show-header="false" style="width: 100%">
      <el-table-column
        :min-width="25"
        prop="name"
        width="auto"
      ></el-table-column>
      <el-table-column
        :min-width="55"
        prop="data"
        width="auto"
      ></el-table-column>
    </el-table>
    <br />
    <!-- 課程資料 -->
    <el-row>
      <div class="pb-3 d-flex">
        <span class="pt-1 pr-2">
          <h3>
            {{ $t("userProfile.student.courseTitle") }}
            <el-tooltip class="item" effect="dark" content="給學生創建報名表" placement="top">
              <span
                v-if="isRoleAdmin() || isRoleParent()"
                @click="
                  () => {
                    toggleEnrollmentDialogVisible(true);
                  }
                "
              >
                <i class="fas fa-plus action-icon" />
              </span>
            </el-tooltip>
          </h3>
        </span>
      </div>
    </el-row>
    <Classes :classes="userDataByAdmin.student.session_classes" />
    <br />
    <el-row>
      <div class="pb-3 d-flex">
        <span class="pt-1 pr-2">
          <h3>
            {{ $t("userProfile.student.collectCollege") }}
            <router-link
              :to="
                `/schools?username=${userDataByAdmin.basic_info.username}`
              "
            >
              <i class="fas fa-plus" />
            </router-link>
          </h3>
        </span>
      </div>
      <CollectCollegeTable
        :collectedCounselingSchools="collectedCounselingSchools"
        @removeSchoolFromFavorite="
          relatedSchoolId => $emit('removeSchoolFromFavorite', relatedSchoolId)
        "
        @fetchCollectedCounselingSchools="
          type => $emit('fetchCollectedCounselingSchools', type)
        "
      />
    </el-row>
    <el-row>
      <div class="pb-3 d-flex">
        <span class="pt-1 pr-2">
          <h3>
            升學輔導
            <el-tooltip
              class="item"
              :content="$t('userProfile.dialog.edit')"
              placement="top"
              popper-class="tooltipColor"
            >
              <span @click="editCounselintDialog = true">
                <i class="el-icon-edit-outline action-icon" />
              </span>
            </el-tooltip>
          </h3>
        </span>
      </div>
      <el-table :data="counselingTypeData" :show-header="false">
        <el-table-column
          :min-width="25"
          width="auto"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.name === '編輯作文平台'">
              {{ scope.row.name }}
              <el-tooltip
                class="item"
                content="在升學輔導內建立作文的時候，會以這裡選擇的平台建立連結"
                placement="top"
                popper-class="tooltipColor"
              >
                <span class="action-icon">
                  <i class="fas fa-question-circle" />
                </span>
              </el-tooltip>
            </span>
            <span v-else-if="scope.row.name === '升學類型'">
              {{ scope.row.name }}
              <el-tooltip
                class="item"
                content="在升學輔導內出現的學校及作文，會以這裡選擇的升學類型為主"
                placement="top"
                popper-class="tooltipColor"
              >
                <span class="action-icon">
                  <i class="fas fa-question-circle" />
                </span>
              </el-tooltip>
            </span>
          </template>
        </el-table-column>
        <el-table-column
          :min-width="55"
          prop="data"
          width="auto"
        ></el-table-column>
      </el-table>
    </el-row>
    <br />
    <!-- 過去考試結果 -->
    <el-row>
      <div class="pt-4">
        <h3>{{ $t("userProfile.student.testResult") }}</h3>
      </div>
    </el-row>
    <SatTestResult
      v-if="(profile.sat_results || []).length > 0"
      :sat_results="profile.sat_results"
    />
    <ActTestResult
      v-if="(profile.act_results || []).length > 0"
      :sat_results="profile.act_results"
    />
    <ToeflTestResult v-if="(profile.toefl_results || []).length > 0" role="admin" />
  </div>
</template>

<script>
import { Decimal } from "decimal.js";
import EditDialogStudent from "@/components/userProfile/adminEditUser/EditDialogStudent";
import EditDialogTest from "@/components/userProfile/adminEditUser/EditDialogTest";
import EditDialogCounseling from "@/components/userProfile/adminEditUser/EditDialogCounseling";
import DialogEnrollSession from "@/components/userProfile/adminEditUser/DialogEnrollSession";
import SatTestResult from "@/components/userProfile/SatTestResult";
import ActTestResult from "@/components/userProfile/ActTestResult";
import ToeflTestResult from "@/components/userProfile/ToeflTestResult";
import CollectCollegeTable from "@/components/school/CollectCollegeTable";
import Classes from "@/components/userProfile/StudentClasses";
import { essayDocumentTypeMap } from "@/components/essay/essayDocumentTypeMap";
import profileTest from "@/mixins/profileTest";
import roleMixin from "@/mixins/role";
import _ from "lodash";
import { Act, Ielts, Psat, Sat, Toefl } from "@/class/test";
import { user } from "@ivy-way/material";

export default {
  components: {
    EditDialogStudent,
    EditDialogTest,
    EditDialogCounseling,
    DialogEnrollSession,
    SatTestResult,
    ActTestResult,
    ToeflTestResult,
    Classes,
    CollectCollegeTable
  },
  props: ["id", "collectedCounselingSchools"],
  mixins: [profileTest, roleMixin],
  data() {
    return {
      profile: {},
      studentTableData: [
        {
          name: "userProfile.student.school",
          data: "",
          edit: true,
          propertyName: "high_school"
        },
        {
          name: "userProfile.student.gradeLevel",
          data: "",
          edit: true,
          propertyName: "high_school_graduation_year"
        },
        {
          name: "userProfile.student.classTaken",
          data: "",
          edit: true,
          propertyName: "taken_class_where"
        },
        {
          name: "userProfile.student.comments",
          data: "",
          edit: true,
          propertyName: "taken_class_comments"
        },
        {
          name: "userProfile.student.prefLang",
          data: "",
          edit: true,
          propertyName: "lang_pref"
        },
        {
          name: "userProfile.student.knowUsFrom",
          data: "",
          edit: true,
          propertyName: "know_us"
        },
        {
          name: "userProfile.student.referral",
          data: "",
          edit: true,
          propertyName: "recommendation_info"
        },
        {
          name: "userProfile.student.notes",
          data: "",
          edit: false
        },
        {
          name: "userProfile.student.parent",
          data: "",
          edit: false,
          propertyName: "parents",
          parent: true
        }
      ],
      testTableData: [
        {
          name: "PSAT",
          data: "",
          edit: true,
          propertyName: "psat"
        },
        {
          name: "SAT",
          value: "satStudent",
          data: "",
          edit: true,
          propertyName: "sat"
        },
        {
          name: "ACT",
          data: "",
          edit: true,
          propertyName: "act"
        },
        {
          name: "TOEFL",
          data: "",
          edit: true,
          propertyName: "toefl"
        },
        {
          name: "IELTS",
          data: "",
          edit: true,
          propertyName: "ielts"
        }
      ],
      courseTableData: [
        {
          name: "userProfile.student.privateLesson",
          data: "",
          edit: true
        }
      ],
      counselingTypeData: [
        {
          name: "升學類型",
          data: "",
          edit: true
        },
        {
          name: "編輯作文平台",
          data: "",
          edit: true
        }
      ],
      testResultTableData: [],
      editDialog: false,
      editDialogTest: false,
      addEnrollmentDialogVisible: false,
      editCounselintDialog: false,
      currentSelectTest: {
        name: "",
        data: {}
      }
    };
  },
  mounted() {
    const profile = this.userDataByAdmin.student;
    this.profile = {
      ...profile,
      sat_results: profile.sat_results.map(result => ({
        ...result,
        max_total_score: Decimal.add(
          result.max_english_score || 0,
          result.max_math_score || 0
        )
      })),
      act_results: profile.act_results.map(result => ({
        ...result
      }))
    };
    this.$emit("fetchCollectedCounselingSchools");
    this.assignData();
    if (this.$route.params.editStudentInfoDialog) {
      this.editDialog = true;
    }
  },
  methods: {
    openTestEdit() {
      this.editDialogTest = true;
      this.currentSelectTest = _.cloneDeep({
        ...this.profile,
        psat: new Psat(this.profile.psat || { user_id: this.id } ),
        sat: new Sat(this.profile.sat || { user_id: this.id } ),
        act: new Act(this.profile.act || { user_id: this.id } ),
        ielts: new Ielts(this.profile.ielts || { user_id: this.id } ),
        toefl: new Toefl(this.profile.toefl || { user_id: this.id } )
      });
      this.currTableData = this.testTableData;
    },
    async updateDialog(status) {
      this.editDialog = false;
      this.editDialogTest = false;
      this.editCounselintDialog = false;
      if (status === "submit") {
        await this.$emit("initialProfile");
        this.profile = this.userDataByAdmin.student;
        this.assignData();
      }
    },
    toggleEnrollmentDialogVisible(status) {
      this.addEnrollmentDialogVisible = status;
    },
    assignData() {
      this.studentTableData[0].data = this.profile.high_school;
      this.studentTableData[1].data = this.getGrade(
        this.profile.high_school_graduation_year
      );
      this.studentTableData[2].data = this.profile.taken_class_where;
      this.studentTableData[3].data = this.profile.taken_class_comments;
      this.studentTableData[4].data = this.profile.lang_pref;
      this.studentTableData[5].data = this.profile.know_us;
      this.studentTableData[6].data = this.profile.recommendation_info;
      this.studentTableData[8].data = this.profile.parents;

      this.counselingTypeData[0].data = this.counselingSchoolTyprMap[this.profile.counseling_schools_type];
      this.counselingTypeData[1].data = essayDocumentTypeMap[this.profile.essay_doc_type];

      // getTestString is from mixin: profileTest
      this.testTableData[0].data = this.getTestString("psat", this.profile);
      this.testTableData[1].data = this.getTestString(
        "satStudent",
        this.profile
      );
      this.testTableData[2].data = this.getTestString("act", this.profile);
      this.testTableData[3].data = this.getTestString("toefl", this.profile);
      this.testTableData[4].data = this.getTestString("ielts", this.profile);
    }
  },
  computed: {
    user() {
      return user;
    },
    userDataByAdmin() {
      return this.$store.getters["profile/getUserDataByAdmin"];
    },
    notNoneTestTableData() {
      return this.testTableData.filter(({ data }) => data !== "None" && data !== "");
    },
    counselingSchoolTyprMap() {
      return {
        "null": "未設置",
        1: "高中升大學",
        2: "大學升研究所"
      };
    }
  }
};
</script>

<style scoped>
.btn-edit:focus {
  outline: none;
}
</style>
