<template>
  <!-- Form -->
  <div>
    <el-dialog
      :visible.sync="dialogFormVisible"
      :before-close="updateVisible"
      :close-on-click-modal="false"
      width="70%"
    >
      <div slot="title">
        <h3>{{ $t("userProfile.dialog.edit") }}</h3>
      </div>
      <!-- PSAT -->
      <template v-if="isStudentTest">
        <h3>PSAT</h3>
        <el-form :model="currentSelectTest.psat" ref="form">
          <el-form-item prop="reading_score" :rules="testRules.psatReading">
            <span slot="label">Reading</span>
            <el-input
              v-model="currentSelectTest.psat.reading_score"
              placeholder="Reading"
            ></el-input>
          </el-form-item>
          <el-form-item prop="grammar_score" :rules="testRules.psatGrammar">
            <span slot="label">Grammar</span>
            <el-input
              v-model="currentSelectTest.psat.grammar_score"
              placeholder="Grammar"
            ></el-input>
          </el-form-item>
          <el-form-item prop="math_score" :rules="testRules.psatMath">
            <span slot="label">Math</span>
            <el-input
              v-model="currentSelectTest.psat.math_score"
              placeholder="Math"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <span slot="label">Total</span>
            <el-input
              :value="
                sumPSAT([
                  currentSelectTest.psat.reading_score,
                  currentSelectTest.psat.grammar_score,
                  currentSelectTest.psat.math_score
                ])
              "
              :disabled="true"
            ></el-input>
          </el-form-item>
          <el-form-item class="d-inline-block pr-3">
            <span slot="label">Is this a real test?</span>
            <el-select
              v-model="currentSelectTest.psat.is_real_test"
              placeholder="Please select"
            >
              <el-option
                v-for="item in isRealTestOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="d-inline-block">
            <span slot="label">Date of test</span>
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="currentSelectTest.psat.taken_on"
              type="date"
              placeholder="Select the date"
              style="width: 100%;"
            ></el-date-picker>
          </el-form-item>
        </el-form>
        <hr class="separate-line" />
      </template>

      <!-- SAT student 檢查 value 而不是 name (因為有分 student 及 teacher) -->
      <h3>SAT</h3>
      <template v-if="isStudentTest">
        <el-form :model="currentSelectTest.sat" ref="form">
          <el-form-item prop="reading_score" :rules="testRules.satReading">
            <span slot="label">Reading</span>
            <el-input
              v-model="currentSelectTest.sat.reading_score"
              placeholder="Reading"
            ></el-input>
          </el-form-item>
          <el-form-item prop="grammar_score" :rules="testRules.satGrammar">
            <span slot="label">Grammar</span>
            <el-input
              v-model="currentSelectTest.sat.grammar_score"
              placeholder="Grammar"
            ></el-input>
          </el-form-item>
          <el-form-item prop="math_score" :rules="testRules.satMath">
            <span slot="label">Math</span>
            <el-input
              v-model="currentSelectTest.sat.math_score"
              placeholder="Math"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <span slot="label">Total</span>
            <el-input
              :value="
                sumSAT_student([
                  currentSelectTest.sat.reading_score,
                  currentSelectTest.sat.grammar_score,
                  currentSelectTest.sat.math_score
                ])
              "
              :disabled="true"
            ></el-input>
          </el-form-item>
          <el-form-item class="d-inline-block pr-3">
            <span slot="label">Is this a real test?</span>
            <el-select
              v-model="currentSelectTest.sat.is_real_test"
              placeholder="Please select"
            >
              <el-option
                v-for="item in isRealTestOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="d-inline-block">
            <span slot="label">Date of test</span>
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="currentSelectTest.sat.taken_on"
              type="date"
              placeholder="Select the date"
              style="width: 100%;"
            ></el-date-picker>
          </el-form-item>
        </el-form>
      </template>

      <!-- SAT teacher 檢查 value 而不是 name (因為有分 student 及 teacher) -->
      <template v-else>
        <el-form :model="currentSelectTest.sat" ref="form">
          <el-form-item prop="english_score" :rules="testRules.satEnglish">
            <span slot="label">English</span>
            <el-input
              v-model="currentSelectTest.sat.english_score"
              placeholder="English"
            ></el-input>
          </el-form-item>
          <el-form-item prop="math_score" :rules="testRules.satMath">
            <span slot="label">Math</span>
            <el-input
              v-model="currentSelectTest.sat.math_score"
              placeholder="Math"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <span slot="label">Total</span>
            <el-input
              :value="
                sumSAT_teacher([
                  currentSelectTest.sat.english_score,
                  currentSelectTest.sat.math_score
                ])
              "
              :disabled="true"
            ></el-input>
          </el-form-item>
          <el-form-item class="d-inline-block pr-3">
            <span slot="label">Is this a real test?</span>
            <el-select
              v-model="currentSelectTest.sat.is_real_test"
              placeholder="Please select"
            >
              <el-option
                v-for="item in isRealTestOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="d-inline-block">
            <span slot="label">Date of test</span>
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="currentSelectTest.sat.taken_on"
              type="date"
              placeholder="Select the date"
              style="width: 100%;"
            ></el-date-picker>
          </el-form-item>
        </el-form>
      </template>
      <!-- ACT -->
      <hr class="separate-line" />
      <h3>ACT</h3>
      <template>
        <el-form :model="currentSelectTest.act" ref="form">
          <el-form-item prop="english_score" :rules="testRules.actEnglish">
            <span slot="label">English</span>
            <el-input
              v-model="currentSelectTest.act.english_score"
              placeholder="English"
            ></el-input>
          </el-form-item>
          <el-form-item prop="math_score" :rules="testRules.actMath">
            <span slot="label">Math</span>
            <el-input
              v-model="currentSelectTest.act.math_score"
              placeholder="Math"
            ></el-input>
          </el-form-item>
          <el-form-item prop="science_score" :rules="testRules.actScience">
            <span slot="label">Science</span>
            <el-input
              v-model="currentSelectTest.act.science_score"
              placeholder="Science"
            ></el-input>
          </el-form-item>
          <el-form-item prop="reading_score" :rules="testRules.actReading">
            <span slot="label">Reading</span>
            <el-input
              v-model="currentSelectTest.act.reading_score"
              placeholder="Reading"
            ></el-input>
          </el-form-item>
          <el-form-item prop="writing_score" :rules="testRules.actEssay">
            <span slot="label">Writing</span>
            <el-input
              v-model="currentSelectTest.act.writing_score"
              placeholder="Writing"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <span slot="label">Average</span>
            <el-input
              :value="
                aveACT([
                  currentSelectTest.act.english_score,
                  currentSelectTest.act.math_score,
                  currentSelectTest.act.science_score,
                  currentSelectTest.act.reading_score
                ])
              "
              :disabled="true"
            ></el-input>
          </el-form-item>
          <el-form-item class="d-inline-block pr-3">
            <span slot="label">Is this a real test?</span>
            <el-select
              v-model="currentSelectTest.act.is_real_test"
              placeholder="Please select"
            >
              <el-option
                v-for="item in isRealTestOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="d-inline-block">
            <span slot="label">Date of test</span>
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="currentSelectTest.act.taken_on"
              type="date"
              placeholder="Select the date"
              style="width: 100%;"
            ></el-date-picker>
          </el-form-item>
        </el-form>
      </template>
      <!-- TOEFL -->
      <hr class="separate-line" />
      <h3>TOEFL</h3>
      <template>
        <el-form :model="currentSelectTest.toefl" ref="form">
          <el-form-item prop="reading_score" :rules="testRules.toeflReading">
            <span slot="label">Reading</span>
            <el-input
              v-model="currentSelectTest.toefl.reading_score"
              placeholder="Reading"
            ></el-input>
          </el-form-item>
          <el-form-item
            prop="listening_score"
            :rules="testRules.toeflListening"
          >
            <span slot="label">Listening</span>
            <el-input
              v-model="currentSelectTest.toefl.listening_score"
              placeholder="Listening"
            ></el-input>
          </el-form-item>
          <el-form-item prop="speaking_score" :rules="testRules.toeflSpeaking">
            <span slot="label">Speaking</span>
            <el-input
              v-model="currentSelectTest.toefl.speaking_score"
              placeholder="Speaking"
            ></el-input>
          </el-form-item>
          <el-form-item prop="writing_score" :rules="testRules.toeflWriting">
            <span slot="label">Writing</span>
            <el-input
              v-model="currentSelectTest.toefl.writing_score"
              placeholder="Writing"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <span slot="label">Total</span>
            <el-input
              :value="
                sumTOEFL([
                  currentSelectTest.toefl.reading_score,
                  currentSelectTest.toefl.listening_score,
                  currentSelectTest.toefl.speaking_score,
                  currentSelectTest.toefl.writing_score
                ])
              "
              :disabled="true"
            ></el-input>
          </el-form-item>
          <el-form-item class="d-inline-block pr-3">
            <span slot="label">Is this a real test?</span>
            <el-select
              v-model="currentSelectTest.toefl.is_real_test"
              placeholder="Please select"
            >
              <el-option
                v-for="item in isRealTestOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="d-inline-block">
            <span slot="label">Date of test</span>
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="currentSelectTest.toefl.taken_on"
              type="date"
              placeholder="Select the date"
              style="width: 100%;"
            ></el-date-picker>
          </el-form-item>
        </el-form>
      </template>
      <!-- IELTS -->
      <hr class="separate-line" />
      <h3>IELTS</h3>
      <template>
        <el-form :model="currentSelectTest.ielts" ref="form">
          <el-form-item prop="reading_score" :rules="testRules.ieltsReading">
            <span slot="label">Reading</span>
            <el-input
              v-model="currentSelectTest.ielts.reading_score"
              placeholder="Reading"
            ></el-input>
          </el-form-item>
          <el-form-item
            prop="listening_score"
            :rules="testRules.ieltsListening"
          >
            <span slot="label">Listening</span>
            <el-input
              v-model="currentSelectTest.ielts.listening_score"
              placeholder="Listening"
            ></el-input>
          </el-form-item>
          <el-form-item prop="speaking_score" :rules="testRules.ieltsSpeaking">
            <span slot="label">Speaking</span>
            <el-input
              v-model="currentSelectTest.ielts.speaking_score"
              placeholder="Speaking"
            ></el-input>
          </el-form-item>
          <el-form-item prop="writing_score" :rules="testRules.ieltsWriting">
            <span slot="label">Writing</span>
            <el-input
              v-model="currentSelectTest.ielts.writing_score"
              placeholder="Writing"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <span slot="label">Average</span>
            <el-input
              :value="
                aveIELTS([
                  currentSelectTest.ielts.reading_score,
                  currentSelectTest.ielts.listening_score,
                  currentSelectTest.ielts.speaking_score,
                  currentSelectTest.ielts.writing_score
                ])
              "
              :disabled="true"
            ></el-input>
          </el-form-item>
          <el-form-item class="d-inline-block pr-3">
            <span slot="label">Is this a real test?</span>
            <el-select
              v-model="currentSelectTest.ielts.is_real_test"
              placeholder="Please select"
            >
              <el-option
                v-for="item in isRealTestOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="d-inline-block">
            <span slot="label">Date of test</span>
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="currentSelectTest.ielts.taken_on"
              type="date"
              placeholder="Select the date"
              style="width: 100%;"
            ></el-date-picker>
          </el-form-item>
        </el-form>
      </template>
      <!-- Dynamic test -->
      <div v-if="type === 'teacher'">
        <div :key="testName" v-for="testName in ['ap', 'ib']">
          <hr class="separate-line" />
          <h3>{{ testName.toUpperCase() }}</h3>
          <template>
            <el-table :data="[...currentSelectTest[testName], { type: 'addItem' }]">
              <el-table-column label="Name">
                <template slot-scope="scope">
                  <el-button
                    v-if="scope.row.type === 'addItem'"
                    type="primary"
                    size="mini"
                    @click="() => { addEditDynamicTest(testName) }"
                  >
                    新增考試
                  </el-button>
                  <el-select
                    v-else-if="scope.row.isEditing"
                    v-model="scope.row.editSubject"
                    filterable
                    placeholder="Subject"
                  >
                    <el-option
                      v-for="item in getOptions(testName)"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                  <span v-else>
                    {{ getLabelFromData(scope.row.subject) }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="Score">
                <template slot-scope="scope">
                  <el-input
                    v-if="scope.row.isEditing"
                    v-model="scope.row.editScore"
                  />
                  <span v-else>{{ scope.row.score }}</span>
                </template>
              </el-table-column>
              <el-table-column label="Action">
                <template slot-scope="scope">
                  <div v-if="scope.row.isEditing">
                    <el-tooltip
                      :value="true"
                      :content="$t('message.remeberSave')"
                      placement="top"
                      popper-class="tooltipColor"
                    >
                      <span
                        key="check"
                        class="action-icon"
                        @click="() => { addDynamicTest(scope.row) }"
                      >
                        <i class="fas fa-check" />
                      </span>
                    </el-tooltip>
                    <span
                      key="times"
                      class="warning-icon"
                      @click="() => { closeEditDynamicTest(testName, scope.row) }"
                    >
                      <i class="fas fa-times" />
                    </span>
                  </div>
                  <div v-else-if="scope.row.type !== 'addItem'">
                    <span class="action-icon" @click="() => { openEditDynamicTest(testName, scope.row) }">
                      <i class="fas fa-edit" />
                    </span>
                    <span
                      class="warning-icon"
                      @click="() => { deleteDynamicTest(testName, scope.row.id) }"
                    >
                      <i class="fas fa-trash-alt" />
                    </span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </div>
      </div>
      <div v-if="type === 'teacher'">
        <hr class="separate-line" />
        <h3>Other test scores</h3>
        <el-alert type="info" show-icon :closable="false">
          <template slot="title">
            輸入格式請依照 考試名稱 (考試分數)，例如：World History (780) AP: Calc BC (5)
          </template>
        </el-alert>
        <el-form>
          <el-form-item>
            <el-input
              type="textarea"
              v-model="currentSelectTest.other_test_scores"
              placeholder="Other test scores"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="updateVisible">
          {{ $t("userProfile.dialog.cancel") }}
        </el-button>
        <el-button type="primary" @click="submitChange">
          {{ $t("userProfile.dialog.save") }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import testHelper from "@/mixins/testHelper";
import profileTest from "@/mixins/profileTest";
import testValidationRules from "@/mixins/testValidationRules";
import subjectsData from "@/data/subjects.json";

export default {
  props: [
    "type",
    "editDialogTest",
    "currentSelectTest",
    "profile",
    "currTableData",
    "id"
  ],
  mixins: [profileTest, testHelper, testValidationRules],
  data() {
    return {
      formLabelWidth: "120px",
      dialogFormVisible: this.editDialogTest,
      newProfile: {},
      isRealTestOptions: [
        {
          value: 1,
          label: "Yes"
        },
        {
          value: 0,
          label: "No"
        }
      ]
    };
  },
  mounted() {
    this.newProfile = this.profile;
  },
  computed: {
    isStudentTest() {
      const sat = this.currTableData.find(({ name }) => name === "SAT");
      return sat.value === "satStudent";
    }
  },
  methods: {
    getOptions(testName) {
      const subjects = subjectsData.find(obj => {
        return obj.label === testName.toUpperCase();
      });

      const existedTests = this.currentSelectTest[testName]
        .filter(({ isEditing }) => !isEditing)
        .map(({subject}) => subject);
      return subjects.options.filter(({ value }) => (
        !existedTests.includes(value)
      ));;
    },
    updateVisible() {
      this.dialogFormVisible = false;
      this.$emit("updateDialog", "cancel");
    },
    async addEditDynamicTest(testName) {
      this.currentSelectTest[testName] = [
        ...this.currentSelectTest[testName],
        { isEditing: true, editSubject: null, editScore: 0 }
      ];
    },
    openEditDynamicTest(testName, dynamicTest) {
      this.currentSelectTest[testName] = this.currentSelectTest[testName].map((test) => {
        if (test === dynamicTest) {
          return {
            ...test,
            isEditing: true,
            editSubject: test.subject,
            editScore: test.score
          };
        } else {
          return test;
        }
      });
    },
    closeEditDynamicTest(testName, dynamicTest) {
      if (dynamicTest.id) {
        this.currentSelectTest[testName] = this.currentSelectTest[testName].map((test) => {
          if (test === dynamicTest) {
            return { ...test, isEditing: false };
          } else {
            return test;
          }
        });
      } else {
        this.currentSelectTest[testName] = this.currentSelectTest[testName].filter((test) => test !== dynamicTest);
      }
    },
    async addDynamicTest(dynamicTest) {
      dynamicTest.isEditing = false;
      dynamicTest.subject = dynamicTest.editSubject;
      dynamicTest.score = dynamicTest.editScore;
    },
    async deleteDynamicTest(testName, id) {
      this.currentSelectTest[testName] = this.currentSelectTest[testName].filter((test) => test.id !== id);

    },
    async submitChange() {
      const isError = document.getElementsByClassName("is-error");
      if (isError.length === 0) {
        this.newProfile = this.currentSelectTest;
        this.dialogFormVisible = false;
        if (this.type === "student") {
          const newObj = {
            id: this.id,
            newProfile: this.newProfile
          };
          await this.$store.dispatch(
            "profile/updateStudentProfileByAdmin",
            newObj
          );
          this.$emit("updateDialog", "submit");
        } else if (this.type === "teacher") {
          const newObj = {
            id: this.id,
            newProfile: this.newProfile
          };
          await this.$store.dispatch(
            "profile/updateTeacherProfileByAdmin",
            newObj
          );
          this.$emit("updateDialog", "submit");
        }
      } else {
        setTimeout(() => {
          const isError = document.getElementsByClassName("is-error");
          const target = isError[0].querySelector("input");
          target.scrollIntoView({
            block: "center",
            behavior: "smooth"
          });
        }, 100);
        return false;
      }
    }
  }
};
</script>

<style></style>
