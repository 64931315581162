<template>
  <!-- Form -->
  <div>
    <el-dialog
      :visible.sync="dialogFormVisible"
      :before-close="updateVisible"
      :close-on-click-modal="false"
      width="70%"
    >
      <div slot="title">
        <h3>{{ $t("userProfile.dialog.edit") }}</h3>
      </div>

      <el-form>
        <el-form-item label="升學類型">
          <el-radio-group
            style="width: 100%"
            :value="newProfile.counseling_schools_type"
            @input="(value) => newProfile.counseling_schools_type = value"
          >
            <div><el-radio :label="1">高中升大學</el-radio></div>
            <div><el-radio :label="2">大學升研究所</el-radio></div>
          </el-radio-group>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="updateVisible">{{
          $t("userProfile.dialog.cancel")
        }}</el-button>
        <el-button type="primary" @click="submitChange">{{
          $t("userProfile.dialog.save")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  props: ["type", "editDialog", "id", "profile"],
  data() {
    return {
      formLabelWidth: "120px",
      dialogFormVisible: this.editDialog,
      newProfile: {}
    };
  },
  mounted() {
    this.newProfile = _.cloneDeep(this.profile);
  },
  methods: {
    updateVisible() {
      this.dialogFormVisible = false;
      this.$emit("updateDialog", "cancel");
    },
    async submitChange() {
      this.dialogFormVisible = false;
      const newObj = {
        id: this.id,
        newProfile: {
          ...this.newProfile,
          parent_ids: this.newProfile.parents.map(
            parent => parent.pivot.parent_user_id
          )
        }
      };

      await this.$store.dispatch("profile/updateStudentProfileByAdmin", newObj);
      this.$emit("updateDialog", "submit");
    }
  }
};
</script>

<style></style>
