<template>
  <div>
    <br />
    <el-dialog
      :title="$t('userProfile.dialog.warning')"
      :visible.sync="deleteTestDialogVisible"
      width="80%"
    >
      <span>{{ $t("userProfile.dialog.deleteTest") }}</span>
      <br />
      <br />
      <h5>{{ currSelectDynamicTestString[currSelectDynamicTestKey] }}</h5>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteTestDialogVisible = false">{{
          $t("userProfile.dialog.cancel")
        }}</el-button>
        <el-button type="primary" @click="deleteDynamicTest">{{
          $t("userProfile.dialog.delete")
        }}</el-button>
      </span>
    </el-dialog>

    <el-row>
      <div class="pb-3 d-flex">
        <span class="pt-1 pr-2">
          <h3>
            {{ $t("userProfile.teacher.teacherInfo") }}
            <el-tooltip
              class="item"
              :content="$t('userProfile.dialog.edit')"
              placement="top"
              popper-class="tooltipColor"
            >
              <span
                @click="
                  editDialog = true;
                  type = 'teacherInfo';
                "
              >
                <i class="el-icon-edit-outline action-icon" />
              </span>
            </el-tooltip>
          </h3>
        </span>
      </div>
    </el-row>

    <template v-if="editDialog">
      <EditDialogTeacher
        :type="type"
        :editDialog="editDialog"
        :profile="profile"
        :id="id"
        @updateDialog="updateDialog"
      ></EditDialogTeacher>
    </template>

    <template v-if="editDialogTest">
      <EditDialogTest
        :type="'teacher'"
        :editDialogTest="editDialogTest"
        :currentSelectTest="currentSelectTest"
        :profile="profile"
        :currTableData="testTableData"
        :id="id"
        @updateDialog="updateDialog"
        @initProfile="initProfile"
      ></EditDialogTest>
    </template>

    <template v-if="editDialogSubjects">
      <EditDialogTeacherSubjects
        :editDialogSubjects="editDialogSubjects"
        :profile="profile"
        :id="id"
        @updateDialog="updateDialog"
      ></EditDialogTeacherSubjects>
    </template>

    <el-table :data="teacherTableData" :show-header="false">
      <el-table-column prop="name" width="auto">
        <template slot-scope="scope">
          <span v-if="scope.row.name === 'userProfile.teacher.tutoringBio'">
            {{ $t(scope.row.name) }}
            <el-tooltip
              :content="$t('userProfile.teacher.tutotingBioTip')"
              placement="top"
              popper-class="tooltipColor"
            >
              <span class="action-icon"><i class="fas fa-question-circle" /></span>
            </el-tooltip>
          </span>
          <span v-else-if="scope.row.name === 'userProfile.teacher.counselingBio'">
            {{ $t(scope.row.name) }}
            <el-tooltip
              :content="$t('userProfile.teacher.counselingBioTip')"
              placement="top"
              popper-class="tooltipColor"
            >
              <span class="action-icon"><i class="fas fa-question-circle" /></span>
            </el-tooltip>
          </span>
          <span v-else-if="scope.$index > 7">
            <el-tooltip
              content="This information is private for Ivy-Way to see only."
              placement="top"
              popper-class="tooltipColor"
            >
              <span class="action-icon">
                <i class="fas fa-user-shield"></i>
              </span>
            </el-tooltip>
            {{ $t(scope.row.name) }}
          </span>
          <span v-else>{{ $t(scope.row.name) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="data" width="auto">
        <template slot-scope="scope">
          <div v-if="scope.row.name === 'userProfile.teacher.showBio'">
            {{ scope.row.data }}
          </div>
          <div v-else-if="scope.row.name === 'userProfile.teacher.gradeLevel'">
            {{ scope.row.data }}
          </div>
          <span
            v-else-if="
              scope.row.name === 'userProfile.teacher.meeting_link_url'
            "
          >
            <a :href="$t(scope.row.data)">{{ $t(scope.row.data) }}</a>
          </span>
          <span v-else-if="scope.$index > 7">
            {{ $t(scope.row.data) }}
          </span>
          <div v-else>{{ helper.displayI18nText($i18n.locale, scope.row.data) }}</div>
        </template>
      </el-table-column>

      <!-- <el-table-column prop="data" width="auto"></el-table-column> -->

      <el-table-column width="auto"></el-table-column>
    </el-table>
    <br />

    <!-- Testing Information -->
    <el-row>
      <div class="pt-4">
        <h3>
          {{ $t("userProfile.teacher.testInfo") }}
          <el-tooltip
            class="item"
            :content="$t('userProfile.dialog.edit')"
            placement="top"
            popper-class="tooltipColor"
          >
            <span @click="openTestEdit">
              <i class="el-icon-edit-outline action-icon" />
            </span>
          </el-tooltip>
        </h3>
      </div>
    </el-row>
    <el-table :data="notNoneTestTableData" :show-header="false" style="width: 100%">
      <el-table-column
        :min-width="25"
        prop="name"
        width="auto"
      ></el-table-column>
      <el-table-column :min-width="55" prop="data" width="auto">
        <template slot-scope="scope">
          <span v-if="typeof scope.row.data === 'string'">{{ scope.row.data }}</span>
          <div v-else v-for="(item, key) in scope.row.data" :key="key">
            <slot>{{ item }}</slot>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <br />
    <!-- Your Availability -->
    <el-row>
      <div class="pb-3 d-flex">
        <span class="pt-1 pr-2">
          <h3>
            {{ $t("userProfile.teacher.availability") }}
            <el-tooltip
              class="item"
              :content="$t('userProfile.dialog.edit')"
              placement="top"
              popper-class="tooltipColor"
            >
              <span
                @click="
                  editDialog = true;
                  type = 'availability';
                "
              >
                <i class="el-icon-edit-outline action-icon" />
              </span>
            </el-tooltip>
          </h3>
        </span>
      </div>
    </el-row>
    <el-table :data="checkUserRole(availabilityTableData)" :show-header="false">
      <el-table-column prop="name" width="auto">
        <template slot-scope="scope">
          <span v-if="scope.row.name === 'userProfile.teacher.teachingPref'">
            {{ $t(scope.row.name) }}
            <el-tooltip
              content="Please describe to our admin the kind of classes you want, including subject(s), date/time (please include timezone), duration. Let us know if you have any"
              placement="top"
              popper-class="tooltipColor"
            >
              <span class="action-icon"><i class="fas fa-question-circle" /></span>
            </el-tooltip>
          </span>
          <span v-else-if="scope.row.name === 'userProfile.teacher.note'">
            <div>
              {{ $t(scope.row.name) }}
              <el-tooltip
                content="只有管理員看得見這個備註"
                placement="top"
                popper-class="tooltipColor"
              >
                <span class="action-icon"><i class="fas fa-question-circle" /></span>
              </el-tooltip>
            </div>
          </span>
          <span v-else>{{ $t(scope.row.name) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="data" width="auto">
        <template slot-scope="scope">
          <template v-if="scope.row.isSubjects">
            <el-tag
              v-for="subject in getLabelOfSubjects(scope.row.nameOfSubjects)"
              :key="subject"
              type="success"
              class="ivy-tag"
              size="mini"
            >
              {{ subject }}
            </el-tag>
          </template>
          <template v-else-if="scope.row.isSalary">
            <div>
              {{
                `Online: ${addCurrencySymbol(
                  scope.row.online_salary,
                  scope.row.online_currency
                )}`
              }}
            </div>
            <div>
              {{
                `In-person: ${addCurrencySymbol(
                  scope.row.in_person_salary,
                  scope.row.in_person_currency
                )}`
              }}
            </div>
          </template>
          <template v-else-if="scope.row.isNote">
            <slot>
              {{ scope.row.data }}
            </slot>
          </template>
          <template v-else>
            <slot>{{ scope.row.data }}</slot>
          </template>
        </template>
      </el-table-column>
      <el-table-column width="auto">
        <template slot-scope="scope">
          <template v-if="scope.row.edit">
            <el-tooltip
              class="item"
              :content="$t('userProfile.dialog.edit')"
              placement="top"
              popper-class="tooltipColor"
            >
              <el-button
                class="btn-edit"
                @click="editDialogSubjects = true"
                type="text"
                size="small"
              >
                <i class="el-icon-edit-outline action-icon" />
              </el-button>
            </el-tooltip>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <br />

    <!-- Your Classes -->
    <el-row>
      <div class="pb-3 d-flex">
        <span class="pt-1 pr-2">
          <h3>{{ $t("userProfile.teacher.yourClass") }}</h3>
        </span>
      </div>
      <Classes :classes="profile.session_classes" />
    </el-row>
    <br />

    <!-- Billing Info -->
    <el-row>
      <div class="pb-3 d-flex">
        <span class="pt-1 pr-2">
          <h3>
            {{ $t("userProfile.teacher.billingInfo") }}
            <el-tooltip
              class="item"
              :content="$t('userProfile.dialog.edit')"
              placement="top"
              popper-class="tooltipColor"
            >
              <router-link
                :to="{
                  name: 'createPaymentWithTeacher',
                  params: { teacherId: id }
                }"
              >
                <i class="fas fa-plus" />
              </router-link>
            </el-tooltip>
          </h3>
        </span>
      </div>
      <el-table :data="teacherBillingInfos">
        <el-table-column label="Method" prop="title" width="120" />
        <el-table-column label="Details">
          <template slot-scope="scope">
            <span :key="index" v-for="(teacherBillingInfo, index) in scope.row.payment">
              {{ teacherBillingInfo[Object.keys(teacherBillingInfo)[0]]
              }}<span v-if="scope.row.payment.length - 1 !== index">,</span>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="Note" width="120">
          <template slot-scope="scope">
            <el-tooltip
              :content="scope.row.note"
              placement="top"
              popper-class="tooltipColor"
            >
              <span v-if="scope.row.note" class="action-icon">
                <i class="fas fa-file-alt" />
              </span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="Is Default" width="120">
          <template slot-scope="scope">
            <span v-if="scope.row.is_default" class="action-icon">
              <i class="fas fa-check" />
            </span>
          </template>
        </el-table-column>
        <el-table-column width="100" label="Action">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              content="Edit"
              placement="top"
              popper-class="tooltipColor"
            >
              <router-link
                :to="{
                  name: 'editPayment',
                  params: { teacherPaymentId: scope.row.id }
                }"
              >
                <i class="el-icon-edit-outline action-icon" />
              </router-link>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
  </div>
</template>

<script>
import EditDialogTeacher from "@/components/userProfile/adminEditUser/EditDialogTeacher";
import EditDialogTest from "@/components/userProfile/adminEditUser/EditDialogTest";
import EditDialogTeacherSubjects from "@/components/userProfile/adminEditUser/EditDialogTeacherSubjects";
import Classes from "@/components/userProfile/TeacherClasses";
import profileTest from "@/mixins/profileTest";
import moneyMixin from "@/mixins/money";
import roleMixin from "@/mixins/role";
import { Act, Ielts, Sat, Toefl } from "@/class/test";
import { helper } from "@ivy-way/material";

import _ from "lodash";
import subjectsData from "@/data/subjects.json";
export default {
  components: {
    EditDialogTeacher,
    EditDialogTest,
    EditDialogTeacherSubjects,
    Classes
  },
  props: ["id", "teacherBillingInfos"],
  mixins: [profileTest, moneyMixin, roleMixin],
  data() {
    return {
      profile: {},
      type: "",
      teacherTableData: [
        {
          name: "userProfile.teacher.showBio",
          data: "",
          edit: true,
          propertyName: "show_bio"
        },
        {
          name: "userProfile.teacher.college",
          data: "",
          edit: true,
          propertyName: "college"
        },
        {
          name: "userProfile.teacher.gradeLevel",
          data: "",
          edit: true,
          propertyName: "college_graduation_year"
        },
        {
          name: "userProfile.teacher.meeting_link_url",
          data: "",
          edit: true,
          propertyName: "meeting_link_url"
        },
        {
          name: "userProfile.teacher.major",
          data: "",
          edit: true,
          propertyName: "college_major"
        },
        {
          name: "userProfile.teacher.publicIntro",
          data: "",
          edit: true,
          propertyName: "bio"
        },
        {
          name: "userProfile.teacher.tutoringBio",
          data: "",
          edit: false,
          propertyName: "tutoring_bio"
        },
        {
          name: "userProfile.teacher.counselingBio",
          data: "",
          edit: false,
          propertyName: "counseling_bio"
        },
        // {
        //   name: "userProfile.teacher.ans_to_extracurriculars",
        //   data: "",
        //   edit: false,
        //   propertyName: "ans_to_extracurriculars"
        // },
        {
          name: "userProfile.teacher.ans_to_teaching_years",
          data: "",
          edit: false,
          propertyName: "ans_to_teaching_years"
        },
        {
          name: "userProfile.teacher.ans_to_student_numbers",
          data: "",
          edit: false,
          propertyName: "ans_to_student_numbers"
        },
        {
          name: "userProfile.teacher.ans_to_capable_subjects",
          data: "",
          edit: false,
          propertyName: "ans_to_capable_subjects"
        },
        {
          name: "userProfile.teacher.ans_to_relevant_work",
          data: "",
          edit: false,
          propertyName: "ans_to_relevant_work"
        },
        {
          name: "userProfile.teacher.ans_to_students_perform",
          data: "",
          edit: false,
          propertyName: "ans_to_students_perform"
        }
      ],
      testTableData: [
        {
          name: "SAT",
          value: "satTeacher",
          data: "",
          edit: true,
          propertyName: "sat"
        },
        {
          name: "ACT",
          data: "",
          edit: true,
          propertyName: "act"
        },
        {
          name: "TOEFL",
          data: "",
          edit: true,
          propertyName: "toefl"
        },
        {
          name: "IELTS",
          data: "",
          edit: true,
          propertyName: "ielts"
        },
        {
          name: "AP",
          data: "",
          edit: true,
          propertyName: "ap",
          isDynamic: true
        },
        {
          name: "IB",
          data: "",
          edit: true,
          propertyName: "ib",
          isDynamic: true
        },
        {
          name: "Other Test Scores",
          data: "",
          edit: true,
          propertyName: "other_test_scores",
          isDynamic: true
        }
      ],
      availabilityTableData: [
        {
          name: "userProfile.teacher.hoursAvailable",
          data: "",
          edit: false,
          propertyName: "hours_available"
        },
        {
          name: "userProfile.teacher.teachingPref",
          data: "",
          edit: false
        },
        {
          name: "userProfile.teacher.subjectsTeach",
          data: "",
          edit: true,
          isSubjects: true,
          nameOfSubjects: [],
          propertyName: "subjects"
        },
        {
          name: "userProfile.teacher.salary",
          data: "",
          isSalary: true,
          edit: false
        },
        {
          name: "userProfile.teacher.note",
          data: "",
          isNote: true,
          edit: false
        }
      ],
      courseTableData: [
        {
          name: "userProfile.teacher.classes",
          data: "",
          edit: true
        }
      ],
      testResultTableData: [],
      editDialog: false,
      currentSelect: {
        name: "",
        value: "",
        data: ""
      },
      editDialogTest: false,
      currentSelectTest: {
        name: "",
        data: {}
      },
      editDialogSubjects: false,
      deleteTestDialogVisible: false,
      currSelectDynamicTestName: "",
      currSelectDynamicTestString: "",
      currSelectDynamicTestKey: 0
    };
  },
  mounted() {
    this.profile = this.userDataByAdmin.instructor;
    this.assignData();
  },
  methods: {
    checkUserRole(availabilityTableData) {
      if (!this.isRoleAdmin()) {
        return availabilityTableData.filter(
          ({ name }) => name !== "userProfile.teacher.note"
        );
      } else {
        return availabilityTableData;
      }
    },
    openTestEdit() {
      this.editDialogTest = true;
      this.currentSelectTest = _.cloneDeep({
        ...this.profile,
        sat: new Sat(this.profile.sat || { user_id: this.id } ),
        act: new Act(this.profile.act || { user_id: this.id } ),
        ielts: new Ielts(this.profile.ielts || { user_id: this.id } ),
        toefl: new Toefl(this.profile.toefl || { user_id: this.id } )
      });
      this.currTableData = this.testTableData;
    },
    handleClickAddDynamicTest(row) {
      this.currSelectDynamicTestName = row.propertyName;
    },

    handleClickDeleteDynamicTest(row, key) {
      this.deleteTestDialogVisible = true;
      this.currSelectDynamicTestName = row.propertyName;
      this.currSelectDynamicTestString = row.data;
      this.currSelectDynamicTestKey = key;
    },
    async deleteDynamicTest() {
      this.profile[this.currSelectDynamicTestName].splice(
        this.currSelectDynamicTestKey,
        1
      );
      const newObj = {
        id: this.id,
        newProfile: this.newProfile
      };
      await this.$store.dispatch("profile/updateTeacherProfileByAdmin", newObj);
      this.deleteTestDialogVisible = false;
      this.profile = this.userDataByAdmin.instructor;
      this.assignData();
    },

    updateDialog(status) {
      this.editDialog = false;
      this.editDialogTest = false;
      this.editDialogSubjects = false;
      if (status === "submit") {
        this.initProfile();
      }
    },
    async initProfile() {
      await this.$emit("initialProfile");
      this.profile = this.userDataByAdmin.instructor;
      this.assignData();
    },
    assignData() {
      if (this.profile.show_bio === 1) this.teacherTableData[0].data = "Yes";
      else this.teacherTableData[0].data = "No";
      this.teacherTableData[1].data = this.profile.college;
      this.teacherTableData[2].data = this.getTeacherGrade(
        this.profile.college_graduation_year
      );
      this.teacherTableData[3].data = this.profile.meeting_link_url;
      this.teacherTableData[4].data = this.profile.college_major;
      this.teacherTableData[5].data = this.profile.bio;
      this.teacherTableData[6].data = this.profile.tutoring_bio;
      this.teacherTableData[7].data = this.profile.counseling_bio;
      // this.teacherTableData[8].data = this.profile.ans_to_extracurriculars;
      this.teacherTableData[8].data = this.profile.ans_to_teaching_years;
      this.teacherTableData[9].data = this.profile.ans_to_student_numbers;
      this.teacherTableData[10].data = this.profile.ans_to_capable_subjects;
      this.teacherTableData[11].data = this.profile.ans_to_relevant_work;
      this.teacherTableData[12].data = this.profile.ans_to_students_perform;

      // getTestString is from mixin: profileTest
      this.testTableData[0].data = this.getTestString(
        "satTeacher",
        this.profile
      );
      this.testTableData[1].data = this.getTestString("act", this.profile);
      this.testTableData[2].data = this.getTestString("toefl", this.profile);
      this.testTableData[3].data = this.getTestString("ielts", this.profile);
      this.testTableData[4].data = this.getTestString("ap", this.profile);
      this.testTableData[5].data = this.getTestString("ib", this.profile);
      this.testTableData[6].data = this.profile.other_test_scores;

      this.availabilityTableData[0].data = this.profile.hours_available;
      this.availabilityTableData[2].data = this.profile.subjects;
      this.availabilityTableData[2].nameOfSubjects = this.getLabelOfSubjects();
      this.availabilityTableData[3].in_person_salary =
        Number(this.profile.in_person_salary) || 0;
      this.availabilityTableData[3].in_person_currency =
        this.profile.in_person_currency || "TWD";
      this.availabilityTableData[3].online_salary =
        Number(this.profile.online_salary) || 0;
      this.availabilityTableData[3].online_currency =
        this.profile.online_currency || "TWD";

      this.availabilityTableData[4].data = this.profile.notes;
    },
    getLabelOfSubjects() {
      let satSelectedArray = [];
      let actSelectedArray = [];
      let toeflSelectedArray = [];
      let counselingSelectedArray = [];
      let apSelectedArray = [];
      let ibSelectedArray = [];
      let schoolSelectedArray = [];
      let greSelectedArray = [];
      let gmatSelectedArray = [];
      let mcatSelectedArray = [];
      (this.profile.subjects || []).forEach(x => {
        // Regex
        if (/^sat[^2]/.test(x)) {
          satSelectedArray.push(x);
        } else if (/^act/.test(x)) {
          actSelectedArray.push(x);
        } else if (/^counseling/.test(x)) {
          counselingSelectedArray.push(x);
        } else if (/^toefl/.test(x)) {
          toeflSelectedArray.push(x);
        } else if (/^ap/.test(x)) {
          apSelectedArray.push(x);
        } else if (/^ib/.test(x)) {
          ibSelectedArray.push(x);
        } else if (/^school/.test(x)) {
          schoolSelectedArray.push(x);
        } else if (/^gre/.test(x)) {
          greSelectedArray.push(x);
        } else if (/^gmat/.test(x)) {
          gmatSelectedArray.push(x);
        } else if (/^mcat/.test(x)) {
          mcatSelectedArray.push(x);
        }
      });
      if (satSelectedArray.length === 4) satSelectedArray = ["SAT"];
      if (actSelectedArray.length === 4) actSelectedArray = ["ACT"];
      if (counselingSelectedArray.length === 3)
        counselingSelectedArray = ["Counseling"];
      if (toeflSelectedArray.length === 4) toeflSelectedArray = ["TOEFL"];
      if (apSelectedArray.length === 27) apSelectedArray = ["AP"];
      if (ibSelectedArray.length === 22) ibSelectedArray = ["IB"];
      if (schoolSelectedArray.length === 8) schoolSelectedArray = ["School"];

      const temp = [
        ...satSelectedArray,
        ...actSelectedArray,
        ...toeflSelectedArray,
        ...counselingSelectedArray,
        ...apSelectedArray,
        ...ibSelectedArray,
        ...schoolSelectedArray,
        ...greSelectedArray,
        ...gmatSelectedArray,
        ...mcatSelectedArray
      ];
      let output = [];

      for (let i = 0; i < temp.length; i++) {
        subjectsData.forEach(x => {
          if (x.label === temp[i]) {
            output.push(temp[i]);
          } else {
            x.options.forEach(y => {
              if (y.value === temp[i]) {
                output.push(y.label);
              }
            });
          }
        });
      }

      return output;
    }
  },
  computed: {
    helper() {
      return helper;
    },
    userDataByAdmin() {
      return this.$store.getters["profile/getUserDataByAdmin"];
    },
    notNoneTestTableData() {
      return this.testTableData.filter(({ data }) => {
        if (Array.isArray(data)) {
          return data[0] !== "None" && data[0] !== "";
        } else {
          return data !== "None" && data !== "";
        }
      });
    }
  }
};
</script>

<style scoped>
.btn-edit:focus {
  outline: none;
}
</style>
