<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :before-close="
      () => {
        $emit('toggleEnrollmentDialogVisible', false);
      }
    "
    :close-on-click-modal="false"
    width="600px"
  >
    <div slot="title">
      <h3>創建報名表</h3>
    </div>
    <el-form label-width="80px">
      <el-form-item required label="報名梯次">
        <el-select
          v-model="enrollSession"
          :placeholder="$t('validation.selectRequired')"
          style="width: 100%"
          filterable
        >
          <el-option
            v-for="session in sessionList"
            :key="session.id"
            :label="helper.displayI18nText($i18n.locale, session.title)"
            :value="session.id"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button
        @click="
          () => {
            $emit('toggleEnrollmentDialogVisible', false);
          }
        "
      >
        {{ $t("button.cancel") }}
      </el-button>
      <el-button type="primary" @click="() => enterToEnroll(true)">
        創建報名表
      </el-button>
      <el-button type="primary" @click="() => enterToEnroll(false)">
        快速創建報名表（無需填寫任何資料）
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import courseApi from "@/apis/course";
import arrangeApi from "@/apis/arrange";
import { helper } from "@ivy-way/material";
import enrollmentApi from "@/views/enrollmentNew/apis/enrollmentNew";

export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    helper() {
      return helper;
    }
  },
  data() {
    return {
      sessionList: [],
      enrollSession: null
    };
  },
  created() {
    this.fetchSessions();
  },
  methods: {
    async fetchSessions() {
      const { course_sessions } = await courseApi.fetchSessions({
        is_visible: 1
      });
      this.sessionList = course_sessions;
    },
    async enterToEnroll(needToUpdateProfile) {
      if (!this.enrollSession) {
        this.$message.error("請選擇梯次！");
        return false;
      }
      if (needToUpdateProfile) {
        let enrollmentId = 0;
        const res = await enrollmentApi.quickEnroll(this.$route.params.id);
        enrollmentId = res.enrollment_form_id;
        this.$router.push({
          name: "EnrollmentNew",
          query: {
            enrollment_id: enrollmentId,
            step: 2,
            isQuick: 1
          }
        });
      } else {
        const { enrolled_session } = await arrangeApi.quickEnrollStudent({
          course_session_id: this.enrollSession,
          student_user_id: this.$route.params.id
        });
        this.routerPush({
          name: "EnrollmentDetail",
          params: { id: enrolled_session.id }
        });
      }
    }
  }
};
</script>

<style></style>
