<template>
  <div class="container-fluid container">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>{{ formattedUserName }}</el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <el-card class="box-card">
      <el-alert
        v-if="isActive !== null && !this.isActive"
        title="該使用者的狀態使用狀況為 Inactive"
        type="warning"
        show-icon
        :closable="false"
        style="margin-bottom: 20px"
      />
      <div class="titleStyle">
        <h1 class="primaryColor" style="font-size: 30px;">
          {{ formattedUserName }}
        </h1>
        <div v-if="isRoleAdmin()" class="tags">
          <UserTags :tags="userTags" />
          <UserTagsEditForm
            :userId="id"
            :userRoles="roles"
            :userTags="userTags"
            @onSaved="initialProfile"
          />
        </div>
        <el-divider></el-divider>
      </div>
      <el-tabs v-model="activeName">
        <el-tab-pane :label="$t('userProfile.basicInfo')" name="info">
          <BasicInfo
            :key="profileBasicInfoKey"
            :id="id"
            @initialProfile="initialProfile"
          />
        </el-tab-pane>
        <el-tab-pane
          :label="$t('userProfile.parentInfo')"
          name="parent"
          v-if="roles.includes('parent')"
        >
          <ParentInfo
            :key="profileParentKey"
            :id="id"
            @initialProfile="initialProfile"
          />
        </el-tab-pane>
        <el-tab-pane
          :label="$t('userProfile.studentInfo')"
          name="student"
          v-if="roles.includes('student')"
        >
          <StudentInfo
            :key="profileStudentKey"
            :id="id"
            :collectedCounselingSchools="collectedCounselingSchools"
            @fetchCollectedCounselingSchools="fetchCollectedCounselingSchools"
            @removeSchoolFromFavorite="removeSchoolFromFavorite"
            @initialProfile="initialProfile"
          ></StudentInfo>
        </el-tab-pane>
        <el-tab-pane
          :label="$t('userProfile.teacherInfo')"
          name="teacher"
          v-if="roles.includes('teacher') && (isRoleAdmin() || isRoleTeacher())"
        >
          <TeacherInfo
            :key="profileTeacherKey"
            :id="id"
            :teacherBillingInfos="teacherBillingInfos"
            @initialProfile="initialProfile"
          ></TeacherInfo>
        </el-tab-pane>
        <el-tab-pane
          v-if="isRoleAdmin()"
          name="setting"
          :label="$t('userProfile.setting.title')"
        >
          <SettingRole
            :id="id"
            :roles="roles"
            :isActive="isActive"
            @initialProfile="initialProfile"
          />
        </el-tab-pane>
        <el-tab-pane v-if="isRoleAdmin()" name="line" label="Line">
          <LineInfo :id="this.$route.params.id" />
        </el-tab-pane>
        <el-tab-pane v-if="isRoleAdmin()" name="application" label="Application">
          <ApplicationInfo :email="email" />
        </el-tab-pane>
        <el-tab-pane v-if="isRoleAdmin() && roles.includes('teacher')" name="ContractInfo" label="合約">
          <ContractInfo :id="id" />
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import { user } from "@ivy-way/material";
import Breadcrumb from "@/components/Breadcrumb";
import BasicInfo from "@/components/userProfile/adminEditUser/BasicInfo";
import StudentInfo from "@/components/userProfile/adminEditUser/StudentInfo";
import TeacherInfo from "@/components/userProfile/adminEditUser/TeacherInfo";
import ParentInfo from "@/components/userProfile/ParentInfo";
import SettingRole from "@/components/userProfile/SettingRole";
import LineInfo from "@/components/userProfile/LineInfo";
import ContractInfo from "@/components/userProfile/ContractInfo";
import ApplicationInfo from "@/components/userProfile/ApplicationInfo";
import UserTags from "@/components/tags/UserTags";
import UserTagsEditForm from "@/views/user/UserTagsEditForm";
import collectSchoolApi from "@/apis/collectSchool";
import paymentApi from "@/apis/payment";
import roleMixin from "@/mixins/role";

export default {
  metaInfo() {
    return {
      title: `${this.formattedUserName} Profile | Ivy-Way Academy`
    };
  },
  components: {
    Breadcrumb,
    BasicInfo,
    ParentInfo,
    StudentInfo,
    TeacherInfo,
    SettingRole,
    LineInfo,
    ContractInfo,
    ApplicationInfo,
    UserTags,
    UserTagsEditForm
  },
  mixins: [roleMixin],
  data() {
    return {
      id: null,
      activeName: "info",
      isActive: null,
      roles: [],
      collectedCounselingSchools: [],
      teacherBillingInfos: []
    };
  },
  async created() {
    await this.initialProfile();

    if (this.isRoleTeacher()) {
      const teacherPaymentRes = await paymentApi.fetchTeacherPayments(
        this.$route.params.id
      );
      this.teacherBillingInfos = teacherPaymentRes.teacher_user_payments;
    }

    if (this.$route.params.editStudentInfoDialog) {
      this.activeName = "student";
    }
  },
  computed: {
    userMethods() {
      return user;
    },
    userDataByAdmin() {
      return this.$store.getters["profile/getUserDataByAdmin"];
    },
    profileBasicInfoKey() {
      return JSON.stringify(this.userDataByAdmin.basic_info);
    },
    profileParentKey() {
      return JSON.stringify(this.userDataByAdmin.parent);
    },
    profileStudentKey() {
      return JSON.stringify(this.userDataByAdmin.student);
    },
    profileTeacherKey() {
      return JSON.stringify(this.userDataByAdmin.instructor);
    },
    formattedUserName() {
      return this.userMethods.displayName(
        this.userDataByAdmin.basic_info?.first_name,
        this.userDataByAdmin.basic_info?.last_name
      );
    },
    email() {
      return this.userDataByAdmin.basic_info?.email;
    },
    userTags() {
      return this.userDataByAdmin.basic_info?.tags || [];
    }
  },
  methods: {
    async initialProfile() {
      if (this.$route.params.id) {
        await this.$store.dispatch(
          "profile/getUserDataByAdmin",
          this.$route.params.id
        );
      } else if (this.$route.query.username) {
        await this.$store.dispatch(
          "profile/getUserDataForAdminByUserName",
          this.$route.query.username
        );
      }
      this.roles = this.userDataByAdmin.basic_info.all_roles || [];

      if (this.roles.includes("parent")) {
        await this.$store.dispatch("currency/fetchExchangeRate");
        this.$store.dispatch("profile/calculateDisplayBalance", {
          parentId: this.userDataByAdmin.basic_info.id,
          balance: this.userDataByAdmin.basic_info.account_balance,
          balanceCurrency: this.userDataByAdmin.basic_info
            .account_balance_currency
        });
      }

      this.isActive = Boolean(this.userDataByAdmin.basic_info.is_active);
      this.id = this.userDataByAdmin.basic_info.id;
    },
    async fetchCollectedCounselingSchools() {
      const {
        counseling_schools
      } = await collectSchoolApi.fetchCollectedCounselingSchools({
        counselingId: 0,
        userId: this.$route.params.id,
        counselingSchoolsType: this.userDataByAdmin.student
          .counseling_schools_type
      });
      this.collectedCounselingSchools = counseling_schools;
    },
    async removeSchoolFromFavorite(schoolId) {
      await this.$store.dispatch("collectSchool/removeRelatedSchool", schoolId);
      this.fetchCollectedCounselingSchools();
    }
  }
};
</script>

<style scoped>
.titleLine {
  text-align: center;
}
.titleStyle {
  display: block;
  overflow: auto;
}
.primaryColor {
  color: #42a16a;
}
.box-card {
  display: inline-block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.container-fluid {
  padding: 0px 20px;
  max-width: 95vw;
}

.tags {
  display: flex;
  flex-wrap: wrap;
}
</style>
